import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { APP_CONFIG } from '@fgp/app-config';
import { BiselType, IProductoCatalogoDetalle, IProductoModeloCatalogo } from '@fgp/product/interfaces';
import { ShopCode } from '@fgp/shared/interfaces';
import { UtilStaticImageUrlModule } from '@fgp/shared/pipes/util-static-image-url';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'fgp-product-icons',
    templateUrl: './product-icons.component.html',
    styles: [
        `
      .logos {
        bottom: 5%;
        left: 7%;
        width: 13%;
        img {
          max-width: 100%;
          margin-bottom: 13px;
        }
      }

      @media screen and (min-width: 576px) {
        .logos {
          bottom: 7%;
          left: 2%;
          width: 12%;
        }
      }
    `
    ],
    imports: [CommonModule, TranslateModule, UtilStaticImageUrlModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductIconsComponent implements OnInit {
  _product: IProductoCatalogoDetalle | IProductoModeloCatalogo;
  @Input() set product(prod: IProductoCatalogoDetalle | IProductoModeloCatalogo) {
    this._product = prod;
  }
  get product() {
    return this._product;
  }

  private translator = inject(TranslateService);
  tiendaCode = inject(APP_CONFIG).tiendaCode;

  private shopCode = ShopCode;
  private funciones = [];
  private colecciones = [];
  private biselType = BiselType;
  private ceramicCode = 'CERAMICA';
  private luzCode = 'luz';
  private coronaRoscaCode = 'corona_rosca';

  public imgPropertiesToShow: {
    src: string;
    folder: string;
    alt: string;
    title?: string;
    height?: number;
    width?: number;
  }[] = [];

  private funciones_smartwatch = ['06', '05'];
  private funciones_icons = {
    '06': 'icon-smartwatch.svg',
    '05': 'icon-smartwatch.svg',
  };

  private movimientos_icons ={
    '04': 'icon-automatic-movement.svg'
  }

  private tipoMaterialCristal ={
    'ZAF': 'icon-sapphire.svg'
  }

  private colecciones_icons = {
    'SWISS MADE': 'icon-swiss-made.png',
    'CHARMING LADY SWAROVSKI': 'swarovski.svg',
    CONNECTED: 'icon-connected.svg'
  };

  private productProperties = {
    coronaRosca: {
      show: false,
      icon: 'icon-corona-giratoria.svg',
      title: this.translator.instant('detalle.producto.rosca.texto')
    },
    biselGiratorio: {
      show: false,
      icon: 'icon-bisel-giratorio.svg',
      title: this.translator.instant('detalle.producto.bisel.texto')
    },
    ceramicArrows: {
      show: false,
      icon: 'icon-ceramic-arrows.svg',
      title: this.translator.instant('detalle.producto.bisel.texto')
    },
    ceramic: {
      show: false,
      icon: 'icon-ceramic.svg',
      title: this.translator.instant('detalle.producto.bisel.texto')
    },
    light: {
      show: false,
      icon: 'icon-light.svg',
      title: this.translator.instant('detalle.producto.luz.texto')
    },
    correa: {
      show: false,
      icon: 'icon-correa.svg',
      title: this.translator.instant('detalle.producto.correa.texto')
    },
    solarEnergy: {
      show: false,
      icon: 'icon-solar-energy.svg',
      title: 'Solar'
    },
    prodFuncion: {
      show: false,
      icon: '',
      title: ''
    },
    prodMov: {
      show: false,
      icon: '',
      title: ''
    },
    tipoMaterialCristal: {
      show: false,
      icon: '',
      title: ''
    },
    prodColeccion: { //coleccion siempre de ultimo
      show: false,
      icon: '',
      title: ''
    },
  };

  ngOnInit() {
    this.setShopFunctions();
    this.setShopCollections();
    this.setImgPropertiesToShow(this._product);
  }

  setShopFunctions() {
    switch (this.tiendaCode) {
      case this.shopCode.Festina:
      case this.shopCode.Lotus:
      case this.shopCode.Perrelet:
      case this.shopCode.Maitres:
      case this.shopCode.Candino:
      case this.shopCode.Jaguar:
      case this.shopCode.TimeRoad:
        this.funciones = ['05', '06', '04'];
        break;
    }
  }

  setShopCollections() {
    switch (this.tiendaCode) {
      case this.shopCode.Candino:
      case this.shopCode.Jaguar:
      case this.shopCode.Maitres:
      case this.shopCode.Perrelet: //this.colecciones = ['SWISS MADE'];
      case this.shopCode.Festina: //this.colecciones = ['SWISS MADE', 'CONNECTED'];
      case this.shopCode.Lotus: //this.colecciones = ['CONNECTED'];
      case this.shopCode.TimeRoad:
        this.colecciones = ['SWISS MADE', 'CHARMING LADY SWAROVSKI', 'CONNECTED'];
        break;
    }
  }

  setImgPropertiesToShow(product: IProductoCatalogoDetalle | IProductoModeloCatalogo) {
    if ('productoPrincipal' in product) {
      this.setPropertiesFromProductoModeloCatalogo(product);
    } else if ('codigo' in product) {
      this.setPropertiesFromProductoCatalogoDetalle(product);
    }
    const images = [];
    for (const key in this.productProperties) {
      if (this.productProperties[key].show) {
        images.push({
          src: this.productProperties[key].icon,
          folder: 'logos',
          alt: this.productProperties[key].title
        });
      }
    }
    this.imgPropertiesToShow = images;
  }

  setPropertiesFromProductoModeloCatalogo(product: IProductoModeloCatalogo) {
    const coleccion = product?.productoPrincipal?.coleccion?.toUpperCase();
    this.productProperties.coronaRosca.show = product?.productoPrincipal?.coronaRosca;
    this.productProperties.biselGiratorio.show =
      product?.productoPrincipal?.codigoMaterialBisel !== this.ceramicCode &&
      (product?.productoPrincipal?.tipoBisel === this.biselType.BIDIRECCIONAL ||
        product?.productoPrincipal?.tipoBisel === this.biselType.UNIDIRECCIONAL);
    this.productProperties.ceramicArrows.show =
      product?.productoPrincipal?.codigoMaterialBisel === this.ceramicCode &&
      (product?.productoPrincipal?.tipoBisel === this.biselType.BIDIRECCIONAL ||
        product?.productoPrincipal?.tipoBisel === this.biselType.UNIDIRECCIONAL);
    this.productProperties.ceramic.show =
      product?.productoPrincipal?.codigoMaterialBisel === this.ceramicCode &&
      product?.productoPrincipal?.tipoBisel !== this.biselType.BIDIRECCIONAL &&
      product?.productoPrincipal?.tipoBisel !== this.biselType.UNIDIRECCIONAL;
    this.productProperties.light.show = product?.productoPrincipal?.luz;
    this.productProperties.correa.show = !!product?.productoPrincipal?.codigoCorrea2;
    this.productProperties.solarEnergy.show = product?.productoPrincipal?.solar;
    this.productProperties.prodColeccion.icon = this.colecciones.includes(coleccion) ? this.colecciones_icons[coleccion] : '';
    this.productProperties.prodColeccion.show = !!this.productProperties.prodColeccion.icon;
    this.productProperties.prodColeccion.title = this.productProperties.prodColeccion.show ? coleccion : '';

    const omitirFuncionSmartwatch =
      this.colecciones.includes(coleccion) &&
      coleccion === 'CONNECTED' &&
      this.funciones.includes(product?.productoPrincipal?.funcion) &&
      this.funciones_smartwatch.includes(product?.productoPrincipal?.funcion);

    this.productProperties.prodFuncion.icon = omitirFuncionSmartwatch
      ? ''
      : this.funciones.includes(product?.productoPrincipal?.funcion)
        ? this.funciones_icons[product?.productoPrincipal?.funcion]
        : '';
    this.productProperties.prodFuncion.show = !!this.productProperties.prodFuncion.icon;
    this.productProperties.prodFuncion.title = product?.productoPrincipal?.funcion;
  }

  setPropertiesFromProductoCatalogoDetalle(product: IProductoCatalogoDetalle) {
    const coleccion = product?.coleccion?.toUpperCase();
    this.productProperties.coronaRosca.show = product?.caracteristicasReloj?.includes(this.coronaRoscaCode);
    this.productProperties.biselGiratorio.show =
      product?.relojCodigoTipoMaterialBisel !== this.ceramicCode &&
      (product?.relojTipoBisel === this.biselType.BIDIRECCIONAL || product?.relojTipoBisel === this.biselType.UNIDIRECCIONAL);
    this.productProperties.ceramicArrows.show =
      product?.relojCodigoTipoMaterialBisel === this.ceramicCode &&
      (product?.relojTipoBisel === this.biselType.BIDIRECCIONAL || product?.relojTipoBisel === this.biselType.UNIDIRECCIONAL);
    this.productProperties.ceramic.show =
      product?.relojCodigoTipoMaterialBisel === this.ceramicCode &&
      product?.relojTipoBisel !== this.biselType.BIDIRECCIONAL &&
      product?.relojTipoBisel !== this.biselType.UNIDIRECCIONAL;
    this.productProperties.light.show = product?.movimiento?.caracteristicas?.includes(this.luzCode);
    this.productProperties.correa.show = !!product?.codigoCorrea2;
    this.productProperties.solarEnergy.show = product?.relojCodigoTipo === 'SO';

    this.productProperties.prodColeccion.icon = this.colecciones.includes(coleccion) ? this.colecciones_icons[coleccion] : '';
    this.productProperties.prodColeccion.show = !!this.productProperties.prodColeccion.icon;
    this.productProperties.prodColeccion.title = this.productProperties.prodColeccion.show ? coleccion : '';

    const omitirFuncionSmartwatch =
      this.colecciones.includes(coleccion) &&
      coleccion === 'CONNECTED' &&
      this.funciones.includes(product?.movimiento?.tipoMovimiento?.codigo) &&
      this.funciones_smartwatch.includes(product?.movimiento?.tipoMovimiento?.codigo);

    this.productProperties.prodFuncion.icon = omitirFuncionSmartwatch
      ? ''
      : this.funciones.includes(product?.movimiento?.tipoMovimiento?.codigo)
        ? this.funciones_icons[product?.movimiento?.tipoMovimiento?.codigo]
        : '';
    this.productProperties.prodFuncion.show = !!this.productProperties.prodFuncion.icon;
    this.productProperties.prodFuncion.title = product?.movimiento?.tipoMovimiento?.codigo;

    this.productProperties.prodMov.icon = this.movimientos_icons[product?.movimiento?.tipoMovimiento?.codigo];
    this.productProperties.prodMov.show = !!this.productProperties.prodMov.icon;
    this.productProperties.prodMov.title = product?.movimiento?.tipoMovimiento?.nombre;

    this.productProperties.tipoMaterialCristal.icon = this.tipoMaterialCristal[product?.relojCodigoTipoMaterialCristal];
    this.productProperties.tipoMaterialCristal.show = !!this.productProperties.tipoMaterialCristal.icon;
    this.productProperties.tipoMaterialCristal.title = product?.relojNombreTipoMaterialCristal;
  }
}
