<div class="favoritos">
  @if (favorite$().clicked) {
    <div class="spinner-border" role="status" (click)="toggleFavorites()">
      <span class="sr-only">Loading...</span>
    </div>
  } @else {
    <svg-icon
      class="cursor-pointer"
      [key]="favorite$().isFavorite ? 'heart-relleno' : 'heart'"
      [color]="favorite$().isFavorite ? color.active : color.default"
      [size]="size"
      (click)="toggleFavorites()"
    />
  }
</div>
