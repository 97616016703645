import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, computed, inject } from '@angular/core';
import { APP_CONFIG, ICON_COLOR } from '@fgp/app-config';
import { FavoriteActions, FavoritesSelectors } from '@fgp/favorites/data-access';
import { IProductoModeloCatalogo } from '@fgp/product/interfaces';
import { ShopCode } from '@fgp/shared/interfaces';
import { selectUrl } from '@fgp/shared/router-state';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { Store } from '@ngrx/store';

@Component({
  selector: 'fgp-favorite-icon',
  imports: [CommonModule, SvgIconComponent],
  templateUrl: './favorite-icon.component.html',
  styleUrl: './favorite-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FavoriteIconComponent {
  private store = inject(Store);
  private appConfig = inject(APP_CONFIG);
  color = inject(ICON_COLOR);
  url$ = this.store.selectSignal(selectUrl);
  @Input({ required: true }) product!: IProductoModeloCatalogo;
  @Input() size = 'lg';

  storeShopping = this.appConfig.tiendaCode;
  shopCode = ShopCode;

  favorite$ = computed(() => this.store.selectSignal(FavoritesSelectors.selectIsFavorite(this.product?.productoPrincipal?.idProducto))());

  toggleFavorites() {
    this.store.dispatch(FavoriteActions.toggleData({ product: this.product }));
  }
}
